<template>
  <section class="section section-hero">
    <div class="section-hero-bg">
      <section-title
        icon="icon-services"
        h2="Látványos bannerekkel hirdetné <br class='d-none d-lg-block'> cégét, termékét vagy szolgáltatását?"
        h2small="Bannerkészítés cégeknek, ügynökségeknek"
        createdate=""
      />
    </div>
    <div class="section section-hero-body section-hero-services">
      <div class="container">
        <div class="row">
          <div class="col-12 col-lg-6 order-2 order-lg-1">
            <p class="h2-small">
              Igazodva vállalkozásának arculatához vagy a már elkészült grafikai
              tervekhez villámgyorsan elkészítjük a kívánt méretekben statikus-
              vagy dinamikus bannereit legyen szó Google hirdetésről, valamelyik
              más hirdetési rendszerről vagy saját weboldaláról.
            </p>

            <div class="box-contents">
              <h3 class="d-none d-lg-block">// Banner készítés</h3>
              <div id="bovebben-a-bannerekrol"></div>
              <br />
              <router-link
                @click="hidden = !hidden"
                class="btn btn-link"
                to="#bovebben-a-bannerekrol"
              >
                <span># Bővebben a bannerekről</span>
              </router-link>

              <router-link
                @click="hidden = !hidden"
                class="btn btn-link"
                to="#banner-meretek"
              >
                <span> # Leggyakoribb banner méretek </span>
              </router-link>

              <router-link
                @click="hidden = !hidden"
                class="btn btn-link"
                to="#technologiak"
              >
                <span> # Technológiák, amiket használunk </span>
              </router-link>

              <router-link
                @click="hidden = !hidden"
                class="btn btn-link btn-link-cta"
                to="#megrendeles"
              >
                <span># A szolgáltatás megrendelése</span>
              </router-link>
            </div>
          </div>
          <div class="col-12 col-lg-6 order-1 order-lg-2">
            <div class="col-6 col-lg-12 m-auto mb-3 mb-lg-0">
              <img
                src="@/assets/img/all/img-box-services-banner-left.png"
                alt=""
                class="img-fluid"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <SectionTitle
    icon="icon-services"
    h2="Bővebben a bannerekről"
    h2small="Alapfogalmak, definíciók, mire jó ez az egész?"
    createdate=""
  />

  <section class="section section-body">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <h3>// Mi is az a banner?</h3>
          <p class="lead">
            A banner egy grafikai hirdetés, amely különböző méretekben, egy vagy
            több képkockákból áll. Erre a felületre kattintva a hirdető
            honlapjára, landing oldalára lehet eljutni. Néhány esetben
            előfordul, hogy a banner önmagában hordozza az információt és így
            nem is kattintható. A bannerek megjelenésük (1994.) pillanatától
            megosztották az internetes közösséget. Valaki hasznosnak tartja,
            valaki meg azon a véleményen van, hogy inkább ártanak. Az
            mindenesetre biztos, hogy jelenlétük kikerülhetetlen (még reklám
            blokkolók használata mellett is).
          </p>

          <h3>// Statikus bannerek</h3>
          <p class="lead">
            Nem tartalmaznak mozgásokat (animációkat) általában egy darab képből
            (és szövegből) állnak. Legelterjedtebb formátum: png, jpg.
            Használatuk néhány esetben indokolt lehet, főleg, ha a banner
            magában hordozza már az információt. Például: időpont változás.
            <br />
            Speciális felhasználási terület még, amikor a HTML5 bannerhez
            kapcsolódóan készül statikus verzió is, hogy amely oldal, eszköz nem
            tudja megjeleníteni az animált bannert azért még a statikus verziója
            megjelenjen. Valamint a tervezés (és jóváhagyás) fázisában a
            kreatívok is statikusak.
          </p>

          <div class="framed">
            <p class="lead">
              <strong>Kreatív</strong> A bannerhez készített grafikai terv,
              amely statikusan tartalmazza az összes megjelenő fázistervet.
              Mivel számos méretben kell dolgozni, így ajánlott legalább a három
              nagy csoporthoz (álló, négyzet, fekvő) készíteni terveket, illetve
              már előre tervezni a méretbeli különbségekkel, hiszen ami jól
              mutat egy négyzetes banneren az egy nagyméretű álló banneren nem
              feltétlenül alkalmazható.
            </p>
          </div>

          <h3>// Dinamikus bannerek</h3>
          <p class="lead">
            A kezdeti időkben (és a mobil eszközök elterjedéséig) a bannerek GIF
            valamint FLASH verziókban készültek. A GIF bannerek minőségük és
            meglehetősen nagy méretük miatt szinte teljesen elvesztették
            szerepüket, míg a FLASH bannereket a mobil eszközök valamint a flash
            technika megszűnésével tünnek el a kínálatból. Szerepüket
            folyamatosan vette át a HTML5 bannerek.
          </p>

          <h3>// A HTML5 banner előnyei</h3>
          <p class="lead">
            Minden modern eszközön (mobil, tablet, monitor) megjelenik. Nem függ
            semmilyen operációs rendszertől (windows, android, ios, linux, osx).
            Minden modern böngésző képes megjeleníteni (chrome, safari, firefox,
            edge, opera).
          </p>

          <h3>// Tiltott formátumok</h3>
          <p class="lead">
            A HTML5 bannerek elterjedésével jó néhány speciális banner típus is
            megjelent a piacon. Ezek elterjedését három dolog hátráltatja:
            <br />
            1. Az emberek nagy részét zavarják a bannerek. Éppen emiatt volt
            rövid életű az az agresszív forma, amelyben sok volt a felbukkanó
            ablak (pop-up és pop-under), valamint az oldal eltakarása. Így ma
            már csak emberi interakció (kattintás, egérmozgás) hatására
            lehetséges a felület kiterjesztése illetve hang bekapcsolása. <br />
            2. Kivitelezésük több munkával jár, ahhoz, hogy céljukat elérjék
            kreatív megoldásokat kell alkalmazni. <br />
            3. A speciális bannereket az adserverek a legtöbb esetben csak
            korlátozott méretben engedélyezik.
          </p>

          <h3>// Mik azok az adserverek?</h3>
          <p class="lead">
            A legtöbb esetben valamilyen hirdetés kiszolgáló rendszer (adserver)
            használatával kerülnek a különböző oldalakra a bannerek. Ezek az
            adserverek arra szolgálnak, hogy minél egyszerűbben és pontosabban
            lehessen beállítani és mérni a banner megjelenési, kattintási és
            egyéb jellemzőit.
          </p>

          <h3>// Tiltott tartalmak</h3>
          <p class="lead">
            Számos olyan tartalmi típus létezik amelyet az adott adserver
            rendszerek nem támogatnak. Ezek tartalmak döntő többsége mindenhol
            hasonló elbírálás alá (teljes tíltás) esik. Néhány ezek közül:
            hamisított árúcikkek (védjegy, emblémák, márkajegyek), veszélyes
            termékek (drog, vegyi anyagok), tisztességtelen magatartás (csalásra
            használható dokumentumok, útmutatók, softwarek), sokkoló tartalmak
            (gyűlölet, diszkrimináció, erőszak).
          </p>

          <h3>// Tiltott tevékenységek</h3>
          <p class="lead">
            A hirdetési hálózatok tiltják azokat a reklámokat, amelyek
            visszaélésre használhatóak (rosszindulató kód, átirányító oldalak,
            megkerülési próbálkozások), adatgyűjtésre használják őket
            (hitelkártya adatok, pénzügyi adatok) vagy a reklám célja a
            megtévesztés, félrevezetés. A legtöbb esetben az animációk hossza is
            korlátozott, valamint egyes funkciók (pl. hang) csak emberi
            beavatkozásra történhetnek.
          </p>

          <h3>// Korlátozott tartalmak</h3>
          <p class="lead">
            Vagy teljes tiltás vagy csak bizonyos feltételek mellett
            engedélyezettek: felnőtt tartalom, meztelenség, alkohol,
            szerencsejáték, gyógyszerek, politikai tartalom, pénzügyi
            szolgáltatások, gyermekeknek szóló tartalmak, vallás.
          </p>

          <h3>// Responsive bannerek</h3>
          <p class="lead">
            A reszponzív felületű weboldalak korában (mobil, táblagép, laptop,
            monitor) felmerül a kérdés, hogy miért is szükséges többféle
            méretben bannereket készíteni, miért nem elegendő egy reszponzív
            banner az összes méretmutációhoz?
            <br />
            Néhány ok, amiért a reszponzív banner a legtöbb esetben nem
            kivitelezhető: <br />
            1. Méretkorlát: a legtöbb hirdetést kiszolgáló rendszer
            méretkorláthoz köti a bannereket. A korlát (150-300Kbye) a legtöbb
            esetben erősen megköti a banneren elhelyezhető grafikák valamint az
            animációs fázisok számát, hiszen a legnagyobb mérethez kellene
            igazítani a képeket, melyek mérete még önállóan is kihívást jelent.
            <br />
            2. Eltérő arányok, dimenziók: <br />
            A grafikai elemeket, képeket, szövegeket máshogy kell elhelyezni a
            különböző méretű bannereken. Minden információ egy 125x125px
            négyzetre nem fér fel, viszont egy 300x1050px banneren meg lehet
            kevésnek bizonyul. Technikailag ez utóbbi még meg is valósítható
            viszont növeli a banner elkészítési idejét.
          </p>
          <h3>// Mi az az AMPHTML banner?</h3>
          <p class="lead">
            Az AMP technikával (Accelerated Mobile Pages) készített hirdetések
            gyorsabbak és biztonságosabbak, mint a szokásos hirdetések, viszont
            kevesebb szolgáltatást biztosítanak, mint a hagyományos HTML5
            bannerek.
          </p>
          <div id="banner-meretek"></div>
          <h3>// Mi az a GWD?</h3>
          <p class="lead">
            A Google Web Designer (GWD) a Google által készített bannerkészítő
            program (Windows, Mac, Linux operációs rendszerekre) , amely
            interaktív HTML5-hirdetéseket és egyéb HTML5-tartalmakat hoz létre.
            Grafikai felületének segítségével az esetek 99%-ban olyan végső
            kódot állít elő, amely használható a legtöbb hirdetési rendszerben
            gond és probléma nélkül használható.
          </p>
        </div>
      </div>
    </div>
  </section>

  <SectionTitle
    icon="icon-services"
    h2="Leggyakoribb banner méretek"
    h2small="A bannerek hatékonyságát nem csak a méretük határozza meg. Az ötletessége, az érdekessége,
        az aktualitása, a holnap, amelyen megjelenik, valamint az, hogy a banner
        azonnal felbukkan-e, vagy előbb görgetni kell a lapon, mielőtt láthatóvá
        válik, mind-mind fontos tényezők."
    createdate=""
  />

  <section class="section section-body">
    <div class="container">
      <div class="row">
        <div class="col-12 col-lg-4">
          <h3>// ÁLLÓ BANNEREK</h3>
          <ul>
            <li>120x240px - Vertical banner</li>
            <li>120x600px - Skyscraper</li>
            <li>160x600px - Wide skyscraper</li>
            <li>300x600px - Half-page ad</li>
            <li>300x1050px - Portrait</li>
          </ul>
        </div>
        <div class="col-12 col-lg-4">
          <h3>// NÉGYZET BANNEREK</h3>
          <ul>
            <li>125x125px - Square Button</li>
            <li>180x150px - Rectangle</li>
            <li>200x200px - Small square</li>
            <li>240x400px - Vertical rectangle</li>
            <li>250x250px - Square</li>
            <li>250x360px - Triple widescreen</li>
            <li>300x250px - Inline (medium) rectangle</li>
            <li>336x280px - Large rectangle</li>
            <li>580x400px - Netboard</li>
          </ul>
        </div>
        <div class="col-12 col-lg-4">
          <h3>// TÉGLALAP BANNEREK</h3>
          <div id="technologiak"></div>
          <ul>
            <li>88x31px - Micro bar</li>
            <li>120x90px - Button 1</li>
            <li>120x60px - Button 2</li>
            <li>300x50px - Mobile banner</li>
            <li>320x50px - Mobile leaderboard</li>
            <li>320x100px - Large mobile banner</li>
            <li>234x60px - Half banner</li>
            <li>300x100px - 3:1 rectangle</li>
            <li>468x60px - Banner</li>
            <li>728x90px - Leaderboard</li>
            <li>930x180px - Top banner</li>
            <li>970x90px - Large leaderboard</li>
            <li>970x250px - Billboard</li>
            <li>980x120px - Panorama</li>
          </ul>
        </div>
      </div>
    </div>
  </section>

  <SectionTitle
    icon="icon-services"
    h2="Technológiák, amiket használunk"
    h2small=""
    createdate=""
  />

  <section v-once class="section section-body section-body-icons">
    <div id="megrendeles"></div>
    <div class="container">
      <div class="row">
        <div class="col-6 col-lg-2 mt-3 text-center">
          <img src="@/assets/img/all/icon-gwd.svg" alt="Google Web Designer" />
          <h3 class="mt-2"># GWD</h3>
        </div>
        <div class="col-6 col-lg-2 mt-3 text-center">
          <img src="@/assets/img/all/icon-html5.svg" alt="HTML5" />
          <h3 class="mt-2"># HTML</h3>
        </div>
        <div class="col-6 col-lg-2 mt-3 text-center">
          <img src="@/assets/img/all/icon-css3.svg" alt="CSS3" />
          <h3 class="mt-2"># CSS</h3>
        </div>
        <div class="col-6 col-lg-2 mt-3 text-center">
          <img src="@/assets/img/all/icon-javascript.svg" alt="JavaScript" />
          <h3 class="mt-2"># JavaScript</h3>
        </div>
        <div class="col-6 col-lg-2 mt-3 text-center">
          <img src="@/assets/img/all/icon-sketch.svg" alt="Sketch" />
          <h3 class="mt-2"># Sketch</h3>
        </div>
        <div class="col-6 col-lg-2 mt-3 text-center">
          <img src="@/assets/img/all/icon-photoshop.svg" alt="Photoshop" />
          <h3 class="mt-2"># Photoshop</h3>
        </div>
      </div>
    </div>
  </section>

  <SectionTitle
    icon="icon-services"
    h2="Banner készítési <br class='d-none d-lg-block'> irányárak, megrendelés"
    h2small="Kedvező árakkal, egyszerű fizetési megoldásokkal rendelheti meg <br class='d-none d-lg-block'> a kívánt bannereket"
    createdate=""
  />

  <section v-if="sent" class="section section-price-box mt-5 mb-5">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <p class="lead text-center">
            Köszönjük a megkeresést, hamarosan jelentkezünk!
          </p>
        </div>

        <div class="col-12 text-center">
          <router-link
            @click="hidden = !hidden"
            class="btn pt-4 pb-4 mt-3 mb-5"
            to="/szolgaltatasaink/statikus-es-dinamikus-banner-keszites"
          >
            <span class="ps-5 pe-5">Új megrendelés? </span>
          </router-link>
        </div>
      </div>
    </div>
  </section>

  <section v-if="!sent" class="section section-price-box mt-5 mb-5">
    <div class="container">
      <div class="row equal-cols">
        <div class="col price-col price-col-1">
          <a
            data-bs-toggle="collapse"
            href="javascript:void(0);"
            role="button"
            aria-expanded="true"
            aria-controls="collapseprice30"
            data-bs-target="#collapseprice30"
          >
            <div class="row">
              <div class="d-none d-xxl-block col-1">
                <img
                  src="@/assets/img/all/icon-question.svg"
                  alt="Kérdés"
                  width="27"
                  height="29"
                />
                <img
                  src="@/assets/img/all/icon-answer.svg"
                  alt="Válasz"
                  width="27"
                  height="71"
                />
              </div>
              <div class="col-11">
                <h5>// Bannerek száma (db)</h5>
                <p class="collapse show" id="collapseprice30">
                  Állítsa be a szükséges darabszámot. (minden különböző méret és
                  variáció új darabnak számít)!
                </p>
              </div>
            </div>
          </a>
        </div>
        <div class="col-xxl-5 price-col price-col-4">
          <div
            class="d-flex justify-content-center align-items-center align-self-center text-center w-100"
          >
            <h4 class="w-75">
              <input
                type="range"
                class="form-range"
                min="1"
                max="20"
                id="range"
                v-model="range"
                v-if="!showform"
              />
              {{ range }}
              <span>db</span>
            </h4>
          </div>
        </div>
      </div>

      <div class="row equal-cols">
        <div class="col price-col price-col-1">
          <a
            data-bs-toggle="collapse"
            href="javascript:void(0);"
            role="button"
            aria-expanded="true"
            aria-controls="collapseprice33"
            data-bs-target="#collapseprice33"
          >
            <div class="row">
              <div class="d-none d-xxl-block col-1">
                <img
                  src="@/assets/img/all/icon-question.svg"
                  alt="Kérdés"
                  width="27"
                  height="29"
                />
                <img
                  src="@/assets/img/all/icon-answer.svg"
                  alt="Válasz"
                  width="27"
                  height="71"
                />
              </div>
              <div class="col-11">
                <h5>// Kreatívok száma (db)</h5>
                <p class="collapse show" id="collapseprice33">
                  Ha nincs kész grafikai terve (kreatív) a bannerekhez, akkor a
                  kívánt számban elkészítjük.
                </p>
              </div>
            </div>
          </a>
        </div>
        <div class="col-xxl-5 price-col price-col-4">
          <div
            class="d-flex justify-content-center align-items-center align-self-center text-center w-100"
          >
            <h4 class="w-75">
              <input
                type="range"
                class="form-range"
                min="0"
                max="20"
                id="range2"
                v-model="range2"
                v-if="!showform"
              />
              {{ range2 }}
              <span>db</span>
            </h4>
          </div>
        </div>
      </div>

      <div class="row equal-cols">
        <div class="col price-col price-col-1">
          <a
            data-bs-toggle="collapse"
            href="javascript:void(0);"
            role="button"
            aria-expanded="true"
            aria-controls="collapseprice40"
            data-bs-target="#collapseprice40"
          >
            <div class="row">
              <div class="d-none d-xxl-block col-1">
                <img
                  src="@/assets/img/all/icon-question.svg"
                  alt="Kérdés"
                  width="27"
                  height="29"
                />
                <img
                  src="@/assets/img/all/icon-answer.svg"
                  alt="Válasz"
                  width="27"
                  height="71"
                />
              </div>
              <div class="col-11">
                <h5>// Módosítások száma (db)</h5>
                <p class="collapse show" id="collapseprice40">
                  A biztosított anyagokkal elkészült statikus kreatívokon
                  illetve a tesztként elkészült animációkon történő ingyenes
                  módosítások száma (1-3db).
                </p>
              </div>
            </div>
          </a>
        </div>
        <div class="col-xxl-5 price-col price-col-4">
          <div
            class="d-flex justify-content-center align-items-center align-self-center text-center"
          >
            <h4>
              {{ modificiation }}
              <span>db</span>
            </h4>
          </div>
        </div>
      </div>

      <div class="row equal-cols">
        <div class="col price-col price-col-1">
          <a
            data-bs-toggle="collapse"
            href="javascript:void(0);"
            role="button"
            aria-expanded="true"
            aria-controls="collapseprice44"
            data-bs-target="#collapseprice44"
          >
            <div class="row">
              <div class="d-none d-xxl-block col-1">
                <img
                  src="@/assets/img/all/icon-question.svg"
                  alt="Kérdés"
                  width="27"
                  height="29"
                />
                <img
                  src="@/assets/img/all/icon-answer.svg"
                  alt="Válasz"
                  width="27"
                  height="71"
                />
              </div>
              <div class="col-11">
                <h5>// Kedvezmény kupon (%)</h5>
                <p class="collapse show" id="collapseprice44">
                  A biztosított kedvezmény (10%, 20%, 30%) kupon 90 napon belül
                  használható fel újabb termék vagy szolgáltatás megrendelése
                  esetén.
                </p>
              </div>
            </div>
          </a>
        </div>
        <div class="col-xxl-5 price-col price-col-4">
          <div
            class="d-flex justify-content-center align-items-center align-self-center text-center"
          >
            <h4>
              {{ coupon }}
              <span>%</span>
            </h4>
          </div>
        </div>
      </div>

      <div class="row equal-cols">
        <div class="col price-col price-col-1">
          <a
            data-bs-toggle="collapse"
            href="javascript:void(0);"
            role="button"
            aria-expanded="true"
            aria-controls="collapseprice45"
            data-bs-target="#collapseprice45"
          >
            <div class="row">
              <div class="d-none d-xxl-block col-1">
                <img
                  src="@/assets/img/all/icon-question.svg"
                  alt="Kérdés"
                  width="27"
                  height="29"
                />
                <img
                  src="@/assets/img/all/icon-answer.svg"
                  alt="Válasz"
                  width="27"
                  height="71"
                />
              </div>
              <div class="col-11">
                <h5>// Határidő (nap)</h5>
                <p class="collapse show" id="collapseprice45">
                  A megfelelő darabszámú banner elkészítésének és átadásának
                  időtartama. Ha csökkenteni szeretne a határidőn kérjen egyedi
                  árajánlatot!
                </p>
              </div>
            </div>
          </a>
        </div>
        <div class="col-xxl-5 price-col price-col-4">
          <div
            class="d-flex justify-content-center align-items-center align-self-center text-center"
          >
            <h4>
              {{ deadline }}
              <span>nap</span>
            </h4>
          </div>
        </div>
      </div>

      <div class="row equal-cols">
        <div class="col price-col price-col-1">
          <a
            data-bs-toggle="collapse"
            href="javascript:void(0);"
            role="button"
            aria-expanded="true"
            aria-controls="collapseprice90"
            data-bs-target="#collapseprice90"
          >
            <div class="row">
              <div class="d-none d-xxl-block col-1">
                <img
                  src="@/assets/img/all/icon-question.svg"
                  alt="Kérdés"
                  width="27"
                  height="29"
                />

                <img
                  src="@/assets/img/all/icon-answer.svg"
                  alt="Válasz"
                  width="27"
                  height="71"
                />
              </div>
              <div class="col-11">
                <h5>// Online konzultáció (igen/nem)</h5>
                <p class="collapse show" id="collapseprice90">
                  Ha úgy érzi, hogy szüksége van bővebb tájékoztatásra akkor a
                  válaszott online platformon bővebb tájékoztatást nyújtunk,
                  külön díj ellenében (+7.620 Ft), a banner készítéssel
                  kapcsolatban (30perc).
                </p>
              </div>
            </div>
          </a>
        </div>
        <div class="col-xxl-5 price-col price-col-4">
          <div
            class="d-flex justify-content-center align-items-center align-self-center text-center"
          >
            <div v-if="!showform" class="form-check form-check-inline">
              <input
                class="btn-check"
                type="radio"
                name="inlineRadioOnline"
                id="option0-online"
                value="false"
                v-model="online"
              />
              <label class="btn pt-3 pb-3" for="option0-online">
                <span class="ps-3 ps-md-5 pe-3 pe-md-5"> nem </span>
              </label>
            </div>
            <div v-if="!showform" class="form-check form-check-inline">
              <input
                class="btn-check"
                type="radio"
                name="inlineRadioOnline"
                id="option1-online"
                value="true"
                v-model="online"
              />
              <label class="btn pt-3 pb-3" for="option1-online">
                <span class="ps-3 ps-md-5 pe-3 pe-md-5"> igen </span>
              </label>
            </div>

            <div v-if="showform">
              <h4 v-if="!online">nem</h4>
              <h4 v-if="online">igen</h4>
            </div>
          </div>
        </div>
      </div>

      <div class="row equal-cols">
        <div class="col price-col price-col-1">
          <a
            data-bs-toggle="collapse"
            href="javascript:void(0);"
            role="button"
            aria-expanded="true"
            aria-controls="collapseprice95"
            data-bs-target="#collapseprice95"
          >
            <div class="row">
              <div class="d-none d-xxl-block col-1">
                <img
                  src="@/assets/img/all/icon-question.svg"
                  alt="Kérdés"
                  width="27"
                  height="29"
                />

                <img
                  src="@/assets/img/all/icon-answer.svg"
                  alt="Válasz"
                  width="27"
                  height="71"
                />
              </div>
              <div class="col-11">
                <h5>// Forrásfájlok [.sketch, .gwd] (igen/nem)</h5>
                <p class="collapse show" id="collapseprice95">
                  A bannerek grafikai tervezését a SKETCH nevű programmal
                  végezzük (https://www.sketch.com/). Ennek forrásfájlja
                  (.sketch) szerkeszthető formában (legtöbbször vektorgrafikus)
                  tartalmazza a bannerek összetevőit. A bannerek animációit
                  illetve a programozási munkákat a Google Web Design nevű
                  programmal végezzük (https://webdesigner.withgoogle.com/). Ha
                  az anyagokat később fel szeretné használni akkor lehet
                  szüksége a forrásfájlokra (+25.400Ft)
                </p>
              </div>
            </div>
          </a>
        </div>
        <div class="col-xxl-5 price-col price-col-4">
          <div
            class="d-flex justify-content-center align-items-center align-self-center text-center"
          >
            <div v-if="!showform" class="form-check form-check-inline">
              <input
                class="btn-check"
                type="radio"
                name="inlineRadioSource"
                id="option0-source"
                value="false"
                v-model="source"
              />
              <label class="btn pt-3 pb-3" for="option0-source">
                <span class="ps-3 ps-md-5 pe-3 pe-md-5"> nem </span>
              </label>
            </div>
            <div v-if="!showform" class="form-check form-check-inline">
              <input
                class="btn-check"
                type="radio"
                name="inlineRadioSource"
                id="option1-source"
                value="true"
                v-model="source"
              />
              <label class="btn pt-3 pb-3" for="option1-source">
                <span class="ps-3 ps-md-5 pe-3 pe-md-5"> igen </span>
              </label>
            </div>

            <div v-if="showform">
              <h4 v-if="!source">nem</h4>
              <h4 v-if="source">igen</h4>
            </div>
          </div>
        </div>
      </div>

      <div class="row equal-cols">
        <div class="col price-col price-col-1">
          <a
            data-bs-toggle="collapse"
            href="javascript:void(0);"
            role="button"
            aria-expanded="true"
            aria-controls="collapseprice100"
            data-bs-target="#collapseprice100"
          >
            <div class="row">
              <div class="d-none d-xxl-block col-1">
                <img
                  src="@/assets/img/all/icon-question.svg"
                  alt="Kérdés"
                  width="27"
                  height="29"
                />
                <img
                  src="@/assets/img/all/icon-answer.svg"
                  alt="Válasz"
                  width="27"
                  height="71"
                />
              </div>
              <div class="col-11">
                <h5>// Irányár (Ft)</h5>
                <p class="collapse show" id="collapseprice100">
                  Az ár tájékoztató jellegű, függ a szöveges anyag
                  mennyiségétől, a használandó grafikai elemektől, a módosítások
                  számától, az animáció(k) bonyolultságától!
                  <br />
                  <strong>
                    A végleges ár meghatározása a részletek megismerése után
                    történik.
                  </strong>
                </p>
              </div>
            </div>
          </a>
        </div>
        <div class="col-xxl-5 price-col price-col-4">
          <div
            class="d-flex justify-content-center align-items-center align-self-center text-center"
          >
            <div class="row">
              <div class="col-12">
                <h4 class="w-100 mt-2">
                  Irányár: {{ totalpricecalculate }} <span>Ft</span>
                </h4>
              </div>
              <div class="col-12">
                <h4 class="w-100 mt-2">
                  <small>
                    ({{ Math.round(totalpricecalculate / range) }}
                    <span>Ft/db)</span>
                  </small>
                </h4>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-if="!showform" class="row equal-cols">
        <div class="col price-col price-col-1"></div>
        <div class="col-xxl-5 price-col price-col-4">
          <div
            class="d-flex justify-content-center align-items-center align-self-center text-center"
          >
            <button @click="showform = true" class="btn btn-send pt-4 pb-4">
              <span class="ps-4 pe-4 text-center">
                Banner készítés megrendelése
              </span>
            </button>
          </div>
        </div>
      </div>

      <div v-if="showform" class="row">
        <div class="d-none d-lg-block col-lg-2 price-col-1"></div>
        <div class="col-12 col-lg-8 price-col-1">
          <Form
            v-if="!sent"
            @submit="sendForm"
            :validation-schema="schema"
            v-slot="{ errors }"
            class="contact-us-form"
          >
            <div class="form-group">
              <label for="name">// Cégnév, kapcsolattartó</label>
              <Field hidden name="banners" :value="this.range" />
              <Field hidden name="creatives" :value="this.range2" />
              <Field hidden name="modificiation" :value="this.modificiation" />
              <Field hidden name="coupon" :value="this.coupon" />
              <Field hidden name="deadline" :value="this.deadline" />
              <Field hidden name="online" :value="this.online" />
              <Field hidden name="source" :value="this.source" />
              <Field hidden name="when" :value="this.when" />
              <Field hidden name="totalprice" :value="totalpricecalculate" />

              <Field
                name="name"
                as="input"
                placeholder="Kérjük, adja meg cégnevét és a kapcsolattartó nevét!"
                class="form-control"
                :class="{ 'is-invalid': errors.name }"
              />
              <div class="invalid-feedback">{{ errors.name }}</div>
            </div>

            <div class="form-group">
              <label for="email">// Email cím</label>
              <Field
                name="email"
                as="input"
                placeholder="Kérjük, adja meg email címét!"
                class="form-control"
                :class="{ 'is-invalid': errors.email }"
              />
              <div class="invalid-feedback">{{ errors.email }}</div>
            </div>

            <div class="form-group">
              <label for="phone_number">// Telefonszám</label>
              <Field
                name="phone_number"
                as="input"
                placeholder="Kérjük, adja meg telefonszámát!"
                class="form-control"
                :class="{ 'is-invalid': errors.phone_number }"
              />
              <div class="invalid-feedback">{{ errors.email }}</div>
            </div>

            <div class="form-group">
              <label for="when">// Mikor keressük telefonon?</label>
              <Field
                name="when"
                as="select"
                class="form-control"
                :class="{ 'is-invalid': errors.service }"
              >
                <option value="" disabled selected>
                  Kérjük, adja meg mikor kereshetjük telefonon?
                </option>
                <option value="9-12">9-12h</option>
                <option value="12-16">12-16h</option>
                <option value="Ne keressenek">
                  Nem kérek telefonos hívást
                </option>
              </Field>
              <div class="invalid-feedback">{{ errors.service }}</div>
            </div>

            <div class="form-group">
              <label for="message">// Üzenet</label>
              <Field
                name="message"
                as="textarea"
                placeholder="Üzenet"
                class="form-control form-message"
                value=""
                :class="{ 'is-invalid': errors.message }"
              />
              <ErrorMessage name="message" />
              <div class="invalid-feedback">{{ errors.message }}</div>
            </div>

            <div class="form-check">
              <Field
                name="accept"
                type="checkbox"
                id="accept"
                value="true"
                class="form-check-input custom-control-input"
                :class="{ 'is-invalid': errors.accept }"
              />
              <label for="accept" class="form-check-label custom-control-label">
                Megismertem és elfogadom az <br />
                <router-link
                  @click="hidden = !hidden"
                  class="btn-privacy-link"
                  to="/adatvedelmi-tajekoztato"
                >
                  <span>adatvédelmi nyilatkozatot</span>
                </router-link>
              </label>
            </div>

            <div class="form-group text-center mt-5">
              <div class="d-grid col-lg-8 m-auto">
                <button type="submit" class="btn pt-4 pb-4 mb-2">
                  <span>Üzenet elküldése</span>
                </button>
                Minden mező kitöltése kötelező. <br />
                A megadott adatokat csak és kizárólag a kapcsolatfelvételhez
                használjuk fel.
              </div>
            </div>
          </Form>
        </div>
        <div class="d-none d-lg-block col-lg-2 price-col-1"></div>
      </div>
    </div>
  </section>

  <SectionTovabbiSzolgaltatasaink actuale="2" />
</template>

<script>
import SectionTitle from "@/components/SectionTitle.vue";
import { Form, Field } from "vee-validate";
import * as Yup from "yup";
import axios from "axios";
import SectionTovabbiSzolgaltatasaink from "../../components/SectionTovabbiSzolgaltatasaink.vue";

export default {
  components: {
    SectionTitle,
    SectionTovabbiSzolgaltatasaink,
    Form,
    Field,
  },
  data() {
    const schema = Yup.object().shape({
      name: Yup.string().required("A név kötelező!"),
      email: Yup.string()
        .required("Az email kötelező!")
        .email("Az email érvénytelen!"),
      phone_number: Yup.string().required("A telefonszám kötelező!"),
      when: Yup.string().required("Kötelező mező!"),
      message: Yup.string(),
      accept: Yup.string().required(
        "Fogadja el az adatkezelési irányelveinket!"
      ),
    });

    return {
      range: 1,
      range2: 0,
      price15: 7620,
      price610: 6350,
      price1120: 5080,
      price215: 7620,
      price2610: 5080,
      price21120: 3810,
      online: false,
      onlineprice: 7620,
      source: false,
      sourceprice: 25400,
      showform: false,
      schema,
      sent: false,
    };
  },

  computed: {
    modificiation() {
      let modificiationqty = 1;
      if (this.range > 3) modificiationqty = 2;
      if (this.range > 10) modificiationqty = 3;
      return modificiationqty;
    },

    coupon() {
      let couponqty = 10;
      if (this.range > 5) couponqty = 20;
      if (this.range > 10) couponqty = 30;
      return couponqty;
    },

    deadline() {
      let deadlineqty = 2;
      if (this.range > 5) deadlineqty = 3;
      if (this.range > 10) deadlineqty = 3;
      return deadlineqty;
    },

    totalpricecalculate() {
      let totalprice = 0;

      if (this.range <= 5) {
        totalprice += this.range * this.price15;
      }

      if (this.range2 > 0 && this.range2 <= 5) {
        totalprice += this.range2 * this.price215;
      }

      if (this.range > 5 && this.range <= 10) {
        totalprice += this.price610 * (this.range - 5) + 5 * this.price15;
      }

      if (this.range2 > 5 && this.range2 <= 10) {
        totalprice += this.price2610 * (this.range2 - 5) + 5 * this.price215;
      }

      if (this.range > 10) {
        totalprice +=
          this.price1120 * (this.range - 10) +
          5 * this.price15 +
          5 * this.price610;
      }

      if (this.range2 > 10) {
        totalprice +=
          this.price21120 * (this.range2 - 10) +
          5 * this.price215 +
          5 * this.price2610;
      }

      if (this.online === "true") totalprice += this.onlineprice;
      if (this.source === "true") totalprice += this.sourceprice;

      return totalprice;
    },
  },

  methods: {
    sendForm(e) {
      axios
        .post("https://arda.hu/api/send_form_email_banners.php", {
          name: e.name,
          email: e.email,
          phone_number: e.phone_number,
          when: e.when,
          message: e.message,
          accept: e.accept,
          banners: e.banners,
          creatives: e.creatives,
          modificiation: e.modificiation,
          coupon: e.coupon,
          deadline: e.deadline,
          online: e.online,
          totalprice: e.totalprice,
        })
        .then(() => {
          this.sent = true;
        });
    },
    onReset() {
      this.sent = false;
    },
  },
};
</script>
